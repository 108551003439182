<template>
    <div>
        <div class="wrapper-page">
            <div class="panel panel-color panel-primary panel-pages">
                <div class="panel-body">
                    <h3 class="text-center m-t-0 m-b-30">
                        <span class=""><img src="../../assets/images/logo.png" alt="logo" height="80"></span>
                    </h3>
                    <form class="form-horizontal m-t-20" id="login-form" method="post"
                        autocomplete="off" role="form">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input type="text" v-model="verify.email" id="email" class="form-control" placeholder="Email *"/>
                                <div v-if="verifyErrors.email != undefined" class="mt-1 mb-2 vuelidate-error">{{ verifyErrors.email }}</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.verify.email.$error && !$v.verify.email.required">Email is required.</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.verify.email.$error && !$v.verify.email.email">Please enter valid email.</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.verify.email.$error && !$v.verify.email.maxLength">Email must have at most {{ $v.verify.email.$params.maxLength.max }} letters.</div>
                            </div>
                        </div>
                        <div class="form-group text-center m-t-20">
                            <div class="col-xs-12">
                                <button type="button" @click="performResetRequest()" class="btn btn-primary w-md waves-effect waves-light">Reset Password</button>
                            </div>
                        </div>
                        <div class="form-group m-t-30 m-b-0">
                            <div class="col-sm-7">
                                <router-link class="ForgetPwd" :to="{ name: 'login' }">Click here to Login</router-link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, maxLength, email } from 'vuelidate/lib/validators';
export default {
    data() {
        return {
            verify: {
                email: '',
            },
            verifyErrors: {}
        }
    },
    validations: { 
        verify: {
            email: {
                required,
                email,
                maxLength: maxLength(250) 
            }
        }
    },
    mounted() {},
    methods: {
        performResetRequest() {
            this.verifyErrors = {};
            this.$v.verify.$touch();
            if (!this.$v.verify.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/auth/forgetpassword', this.verify)
                .then(response => {
                    this.$awn.success(response.data.headers.message);
                    this.$router.push('/login');
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                    if(!error.response.data.errorlist.length){
                        this.verifyErrors = error.response.data.errorlist;
                    }
                });
            }
            else{
                console.log('show error');
            }
        }
    }
}
</script>