<template>
    <div class="container" v-if="isLoaded==1">
        <div class="row">
            <div class="col-md-6">
                <div class="panel panel-light">
                    <div class="panel-heading">
                        <h3 class="panel-title">Edit Testkit</h3>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <form id="summary-form" action="" class="form-horizontal">
                                    <section class="card">
                                        <div class="card-body">
                                            <div class="form-group row">
                                                <label class="col-md-3 control-label text-sm-right pt-2">Scanned Date</label>
                                                <div class="col-md-9">
                                                    <datepicker placeholder="Enter Date" v-model="editForm.scannedDate" :input-class="'form-control'" format="MM-dd-yyyy"></datepicker>
                                                    <div v-if="validationerrors.scannedDate != undefined" class="text-danger">
                                                        {{validationerrors.scannedDate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-3 control-label text-sm-right pt-2">Barcode No</label>
                                                <div class="col-md-9">
                                                    <input type="text" v-model="editForm.barcodeNo" name="barcodeNo" class="form-control" placeholder="Enter Company Name" required />
                                                    <div v-if="validationerrors.barcodeNo != undefined" class="text-danger">
                                                        {{validationerrors.barcodeNo}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-3 control-label text-sm-right pt-2">FedEx No</label>
                                                <div class="col-md-9">
                                                    <input type="text" v-model="editForm.fedexNo" name="barcodeNo" class="form-control" placeholder="Enter FedEx No" required />
                                                    <div v-if="validationerrors.fedexNo != undefined" class="text-danger">
                                                        {{validationerrors.fedexNo}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-md-12 text-center">
                                                    <button type="button" @click="saveTestKit" class="btn btn-primary waves-effect waves-light">Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    computed: {
        kitId() {
            return this.$route.params.kitid;
        },
    },
    data(){
        return {
            isLoaded:0,
            editForm:{
                scannedDate:'',
                barcodeNo:'',
                fedexNo:''
            },
            validationerrors:{}
        }
    },
    mounted(){
        this.loadKitInfo();
    },
    methods : {
        fixDate(event) {
            return moment(event).format("MM-DD-YYYY");
        },
        saveTestKit(){
            axios.post(this.$store.getters.getAPIBasePath + "/testkit/update",{
                testkitId:this.kitId,
                scannedDate:this.fixDate(this.editForm.scannedDate),
                barcodeNo:this.editForm.barcodeNo,
                fedexNo:this.editForm.fedexNo
            })
            .then(response => {
                this.$awn.success('Testkit information updated successfully');
                this.$router.push('/testkits');
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                if(!error.response.data.errorlist.length){
                    this.validationerrors = error.response.data.errorlist;
                    console.log(this.validationerrors);
                }
            });
        },
        loadKitInfo(){
            axios.get(this.$store.getters.getAPIBasePath + '/testkit/view/'+this.kitId)
            .then(response => {
                let apiResponse = response.data.recordinfo;
                this.editForm.scannedDate = apiResponse.scannedDate;
                this.editForm.barcodeNo = apiResponse.barcodeNumber;
                this.editForm.fedexNo = apiResponse.fedexNumber;
                this.isLoaded = 1;
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                this.$router.push('/testkits')
            });
        }
    }
};
</script>
