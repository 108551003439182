<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-light">
                    <div class="panel-heading">
                        <h3 class="panel-title col-md-6">Admin Accounts</h3>
                        <a class="btn btn-primary pull-right" @click="editflag=false" role="button" data-toggle="modal"
                            data-target="#userFormModal" href="javascript:void(0);">Add User</a>
                    </div>
                    <br>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12 m-t-5">
                                <div class="row">
                                    <DataTableSearch @search-update="updateSearchParams"></DataTableSearch>
                                </div>
                                    <table class="table table-bordered table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>First Name</th>
                                                <th>Middle Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Username</th>
                                                <th>Role</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="adminUsers.length==0">
                                                <td colspan="9" align="center">No Admin Users Added</td>
                                            </tr>
                                            <tr v-for="(userinfo, index) in adminUsers" :key="index">
                                                <th scope="row">{{userinfo.id}}</th>
                                                <td>{{userinfo.firstName}}</td>
                                                <td>{{userinfo.middleName}}</td>
                                                <td>{{userinfo.lastName}}</td>
                                                <td>{{userinfo.email}}</td>
                                                <td>{{userinfo.userName}}</td>
                                                <td>{{userinfo.role | UCFirst}}</td>
                                                <td>{{userinfo.isActive == 1 ? 'Active' : 'Disabled'}}</td>
                                                <td>
                                                    <a @click="showUserInfo(userinfo)" data-toggle="modal"
                                                        data-target="#userFormModal" href="javascript:void(0);">Edit</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                <div class="col-md-12 m-t-5">
                                    <!-- <b-pagination v-if="users.length!=0" size="md" class='pull-right'
                                        :current-page="browsepagination.currentpage"
                                        :total-rows="browsepagination.totalrecords" v-model="browsepagination.currentpage"
                                        :per-page="searchdata.limit" @input="loadUsers">
                                    </b-pagination> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UserForm :formdata="userform" :editflag="editflag" @save-success="resetUserForm" />
        </div>
    </div>
</template>
<script>
    import DataTableSearch from '../CommonElements/DataTableSearch';
    import UserForm from './UserForm';
    export default {
        components: {
            DataTableSearch,
            UserForm
        },
        data() {
            return {
                adminUsers: [],
                searchQueries: {
                    limit: 20,
                    pageNo: 1,
                    searchString:'',
                },
                browsepagination: this.$store.getters.getPaginationConfig,
                editflag: false,
                userform: {
                    id: '',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    email: '',
                    role: '',
                    isActive: '',
                    userStatus: ''
                }
            }
        },
        methods: {
            showUserInfo(userinfo) {
                // this.userform = userinfo;
                const { isActive: userStatus, ...rest } = userinfo;
                this.userform = { userStatus, ...rest }
                this.editflag = true;
            },
            resetUserForm() {
                this.userform = {
                    id: '',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    email: '',
                    role: '',
                    isActive: '',
                    userStatus: ''
                };
                this.loadAdminUsers();
            },
            updateSearchParams(newparams) {
                this.browsepagination.currentpage = 1;
                this.searchQueries.searchString = newparams;
                this.loadAdminUsers();
            },
            loadAdminUsers() {
                axios.get(this.$store.getters.getAPIBasePath + "/users",this.searchQueries )
                    .then(response => {
                        let result = response.data;
                        this.adminUsers = result.data;
                        this.browsepagination.currentpage = result.currentpage;
                        this.browsepagination.totalrecords = result.totalrecords;
                        this.browsepagination.totalpages = result.totalpages;
                    })
                    .catch(error => {
                        console.log(error.response.data);
                        // iziToast.error({
                        //     title: 'Warning!',
                        //     message: error.response.data.message,
                        //     position: 'topRight'
                        // });
                        this.adminUsers = [];
                    });
            }
        },
        mounted() {
            this.loadAdminUsers();
        }
    }
</script>