<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-light">
                    <div class="panel-heading">
                        <h3 class="panel-title col-md-6">Test Kits</h3>
                        <a class="btn btn-primary pull-right" role="button" data-toggle="modal" data-target="#testkitUploadModal" href="javascript:void(0);">Upload Data</a>
                        <a class="btn btn-primary pull-right mr-2" target="_blank" @click="showSampleDownloadWarning" href="https://vvdocuments.s3.us-east-2.amazonaws.com/SampleFiles/testkitupload.csv">Download Sample Format</a>
                    </div>
                    <div class="panel-body">
                        <div class="col-md-12 m-t-5">
                            <div class="row">
                                <DataTableSearch @search-update="updateSearchParams"></DataTableSearch>
                            </div>
                            <table class="table table-bordered table-striped mb-0" id="datatable-default">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Scanned Date</th>
                                        <th>Barcode Number</th>
                                        <th>Entered By</th>
                                        <th>Fedex Number</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="testkits.length==0">
                                        <td colspan="7" align="center">No Testkits uploaded</td>
                                    </tr>
                                    <tr v-for="(kit,index) in testkits" :key="index">
                                        <td>{{kit.id}}</td>
                                        <td>{{kit.scannedDate | formatDateOnly}}</td>
                                        <td>{{kit.barcodeNumber}}</td>
                                        <td>{{kit.enteredBy}}</td>
                                        <td>{{kit.fedexNumber}}</td>
                                        <td>
                                            <router-link v-if="kit.applicationId!=null" :to="{ name: 'applicationdetails', params: { applicationid: kit.applicationId }}">
                                                <span class="ti-pencil"></span> View
                                            </router-link>
                                            <span v-if="kit.applicationId==null">Not Activated</span>
                                        </td>
                                        <td>
                                            <a role="button" @click="navigateToEdit(kit)" href="javascript:void(0);">Edit</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-md-12">
                                <b-pagination v-if="testkits.length!=0" size="md" class='pull-right'
                                    :current-page="searchQueries.currentpage"
                                    :total-rows="searchQueries.totalrecords" v-model="searchQueries.pageNo"
                                    :per-page="searchQueries.limit" @input="loadBarcodes">
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <UploadTestKitForm @upload-success="loadBarcodes"/>
    </div>
</template>

<script>
    import UploadTestKitForm from "./Elements/UploadTestKitForm";
    import DataTableSearch from '../CommonElements/DataTableSearch';
    export default {
        components: {
            DataTableSearch,
            UploadTestKitForm
        },
        data() {
            return {
                testkits: [],
                formdata: {
                    scannedDate:'',
                    barcodeNumber:'',
                    fedexNo:''
                },
                searchQueries: {
                    limit: 10,
                    pageNo: 1,
                    searchString:'',
                    totalrecords:'',
                    totalpages:''
                },
            }
        },
        mounted(){
            this.loadBarcodes();
        },
        methods: {
            loadBarcodes(){
                axios.post(this.$store.getters.getAPIBasePath + "/testkit/viewall",this.searchQueries)
                .then((response) => {
                    let result = response.data;
                    this.testkits = result.data;
                    this.searchQueries.pageNo = result.currentpage;
                    this.searchQueries.totalrecords = result.totalrecords;
                    this.searchQueries.totalpages = result.totalpages;
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.testkits = [];
                });
            },
            navigateToEdit(kitInfo){
                if(kitInfo.applicationId!=null){
                    this.$awn.alert('You cannot edit this Testkit. This Testkit is already activated.');
                }
                else{
                    this.$router.push('/testkits/edit/'+kitInfo.id);
                }
            },
            updateSearchParams(newparams) {
                this.searchQueries.currentpage = 1;
                this.searchQueries.searchString = newparams.search;
                this.loadBarcodes();
            },
            showSampleDownloadWarning() {
                this.$awn.warning('Please remove the sample data from CSV before uploading');
            }
        }
    }
</script>