<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-light">
          <div class="panel-heading">
            <h3 class="panel-title col-md-6">Test Applications</h3>
          </div>
          <div class="panel-body">
            <div class="row align-items-end mb-4 datatable-filter">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="searchQueries.name"  placeholder="Enter name" />
                  <div v-if="validationerrors.name != undefined" class="text-danger">
                      {{ validationerrors.name }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Email</label>
                  <input  type="text"  class="form-control"  v-model="searchQueries.email"  placeholder="Enter email" />
                  <div v-if="validationerrors.email != undefined" class="text-danger">
                      {{ validationerrors.email }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Phone No</label>
                  <input type="text" class="form-control"  v-model="searchQueries.phoneNo"  placeholder="Enter Phone" />
                  <div v-if="validationerrors.phoneNo != undefined" class="text-danger">
                      {{ validationerrors.phoneNo }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>From</label>
                  <datepicker  placeholder="Enter Date" :input-class="'form-control'"  format="MM-dd-yyyy" @input="searchQueries.appliedDateFrom = fixDate($event)"></datepicker>
                  <div v-if="validationerrors.appliedDateFrom != undefined" class="text-danger">
                      {{ validationerrors.appliedDateFrom }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>To</label>
                  <datepicker  placeholder="Enter Date" :input-class="'form-control'" format="MM-dd-yyyy" @input="searchQueries.appliedDateTo = fixDate($event)"></datepicker>
                  <div v-if="validationerrors.appliedDateTo != undefined" class="text-danger">
                      {{ validationerrors.appliedDateTo }}
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-4">
                <div class="form-group mb-0">
                  <label>Date</label>
                  <div class="form-row mb-2 align-items-center">
                    <label class="col-sm-3 control-label-filter">From</label>
                    <div class="col-sm-9">
                      <datepicker  placeholder="Enter Date" :input-class="'form-control'"  format="MM-dd-yyyy" @input="searchQueries.appliedDateFrom = fixDate($event)"></datepicker>
                      <div v-if="validationerrors.city != undefined" class="text-danger">
                          {{
                              validationerrors.city[0]
                          }}
                      </div>
                    </div>
                  </div>
                  <div class="form-row align-items-center">
                    <label class="col-sm-3 control-label-filter">To</label>
                    <div class="col-sm-9">
                      <datepicker  placeholder="Enter Date" :input-class="'form-control'" format="MM-dd-yyyy" @input="searchQueries.appliedDateTo = fixDate($event)"></datepicker>
                      <div v-if="validationerrors.city != undefined" class="text-danger">
                          {{
                              validationerrors.city[0]
                          }}
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-md-12 text-center">
                <button class="btn btn-primary" v-on:click="updateSearchParams" type="button">Apply Filter</button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 m-t-5">
                <table class="table table-bordered table-striped mb-0"  id="datatable-default">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone No</th>
                      <th>DOB</th>
                      <th>Applied At</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="applications.length == 0">
                      <td colspan="8" align="center">No Applications Found</td>
                    </tr>
                    <tr v-for="(application, index) in applications" :key="index">
                      <td>{{ application.id }}</td>
                      <td>{{ application.patientInfo.firstName }} {{ application.patientInfo.lastName }}</td>
                      <td>{{ application.patientInfo.email }}</td>
                      <td>{{ application.patientInfo.phoneNo }}</td>
                      <td>{{ application.patientInfo.dob }}</td>
                      <td>{{ application.appliedDate  }}</td>
                      <td>{{ application.applicationStatus }}</td>
                      <td>
                        <router-link :to="{name: 'applicationdetails',params: { applicationid: application.id },}">
                          <span class="ti-pencil"></span>View
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-md-12">
                  <b-pagination v-if="applications.length!=0" size="md" class='pull-right'
                      :current-page="searchQueries.currentpage"
                      :total-rows="searchQueries.totalrecords" v-model="searchQueries.pageNo"
                      :per-page="searchQueries.limit" @input="loadRecords">
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DataTableSearch from "../CommonElements/DataTableSearch";
export default {
  components: {
    DataTableSearch,
  },
  data() {
    return {
      applications: [],
      searchQueries: {
        limit: 10,
        pageNo: 1,
        searchString:'',
        totalrecords:'',
        totalpages:'',
        applicationId: "",
        name: "",
        email: "",
        phoneNo: "",
        appliedDateFrom: null,
        appliedDateTo: null,
      },
      validationerrors: {},
    };
  },
  methods: {
    fixDate(event) {
      return moment(event).format("MM-DD-YYYY");
    },
    updateSearchParams() {
      this.loadRecords();
    },
    loadRecords() {
      axios
        .post(
          this.$store.getters.getAPIBasePath + "/applications",
          this.searchQueries
        )
        .then((response) => {
          let result = response.data;
          this.applications = result.data;
          this.searchQueries.pageNo = result.currentpage;
          this.searchQueries.totalrecords = result.totalrecords;
          this.searchQueries.totalpages = result.totalpages;
        })
        .catch((error) => {
          if(!error.response.data.headers.length){
              this.$awn.alert(error.response.data.headers.message);
          }
          if(!error.response.data.errorlist.length){
            this.validationerrors = error.response.data.errorlist;
          }
          this.applications = [];
        });
    },
  },
  mounted() {
    this.loadRecords();
  },
};
</script>
