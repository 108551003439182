<template>
   <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="panel panel-light">
                    <div class="panel-heading">
                        <h3 class="panel-title">Change Password</h3>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <form id="summary-form" action="" class="form-horizontal">
                                    <section class="card">
                                        <div class="card-body">
                                            <div class="form-group row">
                                                <label for="current_password" class="col-md-5 control-label text-sm-right pt-2">Current Password *</label>
                                                <div class="col-md-7">
                                                    <input v-model="changePassword.password" id="current_password" type="password" name="current_password" class="form-control" placeholder="Please enter your current Password">
                                                    <div v-if="validationErrors.password != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.password }}</div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="new_password" class="col-md-5 control-label text-sm-right pt-2">New Password *</label>
                                                <div class="col-md-7">
                                                    <input v-model="changePassword.newPassword" id="new_password" type="password" name="new_password" class="form-control" placeholder="Please enter your new Password">
                                                    <div v-if="validationErrors.newPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.newPassword }}</div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="confirm_password" class="col-md-5 control-label text-sm-right pt-2">Confirm Password *</label>
                                                <div class="col-md-7">
                                                    <input v-model="changePassword.confirmPassword" id="confirm_password" type="password" name="confirm_password" class="form-control" placeholder="Please confirm your new Password">
                                                    <div v-if="validationErrors.confirmPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ validationErrors.confirmPassword }}</div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-md-12 text-center">
                                                    <button type="button" class="btn btn-primary waves-effect waves-light" @click="processChangePassword">Change Password</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
export default {
    data() {
        return {
            changePassword:{
                password:'',
                newPassword:'',
                confirmPassword:''
            },
            validationErrors:{}
        }
    },
    mounted() {
        
    },
    methods: {
        processChangePassword(){
            this.validationErrors = {};
            axios.post(this.$store.getters.getAPIBasePath + '/profile/changepassword', this.changePassword)
            .then(response => {
                this.$awn.success(response.data.headers.message);
                this.$router.push("/dashboard");
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                if(!error.response.data.errorlist.length){
                    this.validationErrors = error.response.data.errorlist;
                }
            });
        }
    }
}
</script>