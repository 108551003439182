import ViewApplications from '../components/TestApplications/ViewApplications';
import ApplicationDetails from '../components/TestApplications/ApplicationDetails';

export default [
    {
        path: '/applications',
        name: 'viewapplications',
        component: ViewApplications,
        meta: { layout: 'dashboard' }
    },
    {
        path: '/applications/details/:applicationid',
        name: 'applicationdetails',
        component: ApplicationDetails,
        meta: { layout: 'dashboard' }
    },
];