<template>
    
</template>
<script>
import { decryptData } from "../../helpers/AuthUtils";
export default {
    mounted(){
        this.doLogout();
    },
    methods:{
        doLogout() {
            this.$store.commit("saveAccessToken", null);
            let savedKey = localStorage.getItem(this.$store.getters.getLocalStorageKeyName);
            let decodedKey = decryptData(savedKey);
            localStorage.removeItem(this.$store.getters.getLocalStorageName);
            localStorage.removeItem(this.$store.getters.getLocalStorageKeyName);
            axios.post(this.$store.getters.getAPIBasePath + "/auth/logout", {refreshKey: decodedKey})
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {});
            this.$router.push("/login");
        }
    }
}
</script>