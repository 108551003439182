<template>
    <div class="modal" id="testkitUploadModal" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title col-sm-8 p-0">Upload Barcode Data</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <input type="file" class="form-control" ref="uploadfile" v-on:change="handleFileUpload()" accept=".csv" placeholder="Select a CSV File" required />
                </div>
                <div class="modal-footer">
                    <button type="button" @click="uploadFile" class="btn btn-primary">Upload</button>
                    <button type="button" id="usermodalclose" class="btn btn-danger"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
       data() {
            return {
                validation: {},
                file: ''
            }
        },
        methods: {
            uploadFile() {
                let formData = new FormData();
                formData.append('uploadFile', this.file);
                axios.post(this.$store.getters.getAPIBasePath + "/testkit/bulkupload",formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    this.$awn.success('Teskits Uploaded successfully');
                    this.$emit("upload-success")
                })
                .catch(error => {
                    if(!error.response.data.errorlist.length){
                        this.$awn.alert(error.response.data.errorlist.uploadFile);
                    }
                    else if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                });
            },
            handleFileUpload() {
                this.file = this.$refs.uploadfile.files[0];
            }
        }
    }
</script>