<template>
    <div class="modal" id="userFormModal" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 v-if="editflag" class="modal-title col-sm-8 p-0">User Information</h4>
                    <h4 v-if="!editflag" class="modal-title col-sm-8 p-0">New User</h4>
                    <button type="button" @click="triggerClose" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label text-sm-right pt-2">First Name <span
                                        class="required">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" name="firstname" v-model="formdata.firstName"
                                        placeholder="First Name" class="form-control" required />
                                    <div v-if="validationerrors.firstName != undefined" class="text-danger">
                                        {{validationerrors.firstName}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 control-label text-sm-right pt-2">Middle Name <span
                                        class="required">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" name="middleName" v-model="formdata.middleName"
                                        placeholder="Middle Name" class="form-control" required />
                                    <div v-if="validationerrors.middleName != undefined" class="text-danger">
                                        {{validationerrors.middleName}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 control-label text-sm-right pt-2">Last Name <span
                                        class="required">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" name="lastName" v-model="formdata.lastName"
                                        placeholder="Last Name" class="form-control" required />
                                    <div v-if="validationerrors.lastName != undefined" class="text-danger">
                                        {{validationerrors.lastName}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 control-label text-sm-right pt-2">Email <span
                                        class="required">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" name="email" v-model="formdata.email" placeholder="Email"
                                        class="form-control" required />
                                    <div v-if="validationerrors.email != undefined" class="text-danger">
                                        {{validationerrors.email}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 control-label text-sm-right pt-2">Username <span
                                        class="required">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" name="email" v-model="formdata.userName" placeholder="Username"
                                        class="form-control" required />
                                    <div v-if="validationerrors.userName != undefined" class="text-danger">
                                        {{validationerrors.userName}}
                                    </div>
                                </div>
                            </div>
                            <div v-if="!editflag" class="form-group row">
                                <label class="col-sm-3 control-label text-sm-right pt-2">Password <span
                                        class="required">*</span></label>
                                <div class="col-sm-9">
                                    <input type="password" name="email" v-model="formdata.password" placeholder="Password"
                                        class="form-control" required />
                                    <div v-if="validationerrors.password != undefined" class="text-danger">
                                        {{validationerrors.password}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 control-label text-sm-right pt-2">Role <span
                                        class="required">*</span></label>
                                <div class="col-sm-9">
                                    <select id="company" class="form-control custom-select" v-model="formdata.role" required>
                                        <option value="">--Select--</option>
                                        <option v-for="(val,index) in roles" :key="index" :value="val">
                                            {{ val | UCFirst }}</option>
                                    </select>
                                    <div v-if="validationerrors.role != undefined" class="text-danger">
                                        {{validationerrors.role}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 control-label text-sm-right pt-2">Status <span
                                        class="required">*</span></label>
                                <div class="col-sm-9">
                                    <select id="company" class="form-control" v-model="formdata.userStatus" required>
                                        <option value="">--Select--</option>
                                        <option value="1">Active</option>
                                        <option value="0">Disabled</option>
                                    </select>
                                    <div v-if="validationerrors.userStatus != undefined" class="text-danger">
                                        {{validationerrors.userStatus}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="!editflag" type="button" @click="saveUser" class="btn btn-primary">Add User</button>
                    <button v-if="editflag" type="button" @click="updateUser" class="btn btn-primary">Update
                        User</button>
                    <button type="button" @click="triggerClose" id="usermodalclose" class="btn btn-danger"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            formdata: Object,
            editflag: Boolean
        },
        data() {
            return {
                validationerrors: {},
                roles: this.$store.getters.getAdminRoles,
            }
        },
        methods: {
            triggerClose() {
                this.$emit("save-success");
            },
            saveUser() {
                this.validationerrors = {};
                axios.post(this.$store.getters.getAPIBasePath + '/users/add', this.formdata)
                    .then(response => {
                        this.triggerClose();
                        $("#usermodalclose").trigger('click');
                        this.$awn.success('User information added successfully');
                    })
                    .catch(error => {
                        if(!error.response.data.headers.length){
                            this.$awn.alert(error.response.data.headers.message);
                        }
                        if(!error.response.data.errorlist.length){
                            this.validationerrors = error.response.data.errorlist;
                            console.log(this.validationerrors);
                        }
                    });
            },
            updateUser() {
                this.validationerrors = {};
                axios.post(this.$store.getters.getAPIBasePath + '/users/edit/' + this.formdata.id, this.formdata)
                    .then(response => {
                        this.triggerClose();
                        $("#usermodalclose").trigger('click');
                        this.$awn.success('User information updated successfully');
                    })
                    .catch(error => {
                        if(!error.response.data.headers.length){
                            this.$awn.alert(error.response.data.headers.message);
                        }
                        if(!error.response.data.errorlist.length){
                            this.validationerrors = error.response.data.errorlist;
                            console.log(this.validationerrors);
                        }
                    });
            }
        }
    }
</script>