<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <router-link class="float-right p-4" :to="{ name: 'patientsprofile' }">Back</router-link>
                <PatientProfileForm :patientDetails="patientDetails"/>
            </div>
        </div>
    </div>
</template>
<script>
import PatientProfileForm from './PatientProfileForm'
export default {
    components: {
            PatientProfileForm
        },
    computed: {
        patientid() {
            return this.$route.params.patientId;
        },
    },
    data() {
        return {
            patientDetails: {}
        }
    },
    methods: {
        loadProfileDetails() {
        axios
            .get(this.$store.getters.getAPIBasePath + "/patientprofiles/view/" + this.patientid)
            .then((response) => {
                    //this.patientDetails = response.data.recordinfo;
                    const { relationToPatient: patientRelationship, ...rest } = response.data.recordinfo;
                    this.patientDetails = { patientRelationship, ...rest }
                    //this.dateofbirth = response.data.recordinfo.dob;
                })
            .catch((error) => {
                console.log(error);
            });
        },
    },
    mounted() {
        this.loadProfileDetails();
    },
    watch() {

    }
}
</script>
