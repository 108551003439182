import Login from '../components/Auth/Login';
import Logout from '../components/Auth/Logout';
import Dashboard from '../components/Dashboard/Dashboard';
import ForgotPassword from '../components/Auth/ForgotPassword';
import ResetPassword from '../components/Auth/ResetPassword';

import testapplicationroutes from './testapplicationroutes';
import adminusersroutes from './adminusersroutes';
import customersroutes from './customersroutes';
import testkitroutes from './testkitroutes';
import patientprofileroutes from './patientprofileroutes';

const baseroutes = [
	{
		path: '/',
		name: 'default',
		component: Login,
		meta: { layout: 'default' }
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: { layout: 'default' }
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		meta: { layout: 'default' }
	},
	,
	{
		path: '/forgotpassword',
		name: 'forgotpassword',
		component: ForgotPassword,
		meta: { layout: 'default' }
	},
	{
		path: '/resetpassword/:resetkey',
		name: 'resetpassword',
		component: ResetPassword,
		meta: { layout: 'default' }
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		meta: { layout: 'dashboard' }
	}
];

const routes = baseroutes.concat(testapplicationroutes, adminusersroutes, customersroutes, testkitroutes, patientprofileroutes);

export default routes;
