<template>
	<div class="container">
		<div class="row">
		<div class="col-md-12">
			<!-- patient profile form -->
			<PatientProfileForm :patientDetails="patientDetails"/>
			<div class="panel panel-light">
				<div class="panel-heading">
					<h3 class="panel-title">Insurance information</h3>
				</div>
				<div class="panel-body">
					<section class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-md-4">
									<div class="form-group row">
										<label class="col-sm-5 control-label text-sm-right ">SSN No</label>
										<div class="col-sm-7">
											<input type="text" class="form-control" v-model="applicationDetails.ssnNo" placeholder="Enter SSN no"/>
											<div v-if="validationerrors.ssnNo != undefined" class="text-danger">
												{{ validationerrors.ssnNo }}
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group row">
									<label class="col-sm-5 control-label text-sm-right ">License Number</label>
									<div class="col-sm-7">
										<input type="text" class="form-control" v-model="applicationDetails.drivingLicenseNo" placeholder="Enter license number"/>
										<div v-if="validationerrors.drivingLicenseNo != undefined" class="text-danger">
											{{ validationerrors.drivingLicenseNo }}
										</div>
									</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group row">
									<label class="col-sm-5 control-label text-sm-right ">Insurance Name</label>
									<div class="col-sm-7">
										<input type="text" class="form-control" v-model="applicationDetails.insuranceName" placeholder="Enter insurance name"/>
										<div v-if="validationerrors.insuranceName != undefined" class="text-danger">
											{{ validationerrors.insuranceName }}
										</div>
									</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group row">
										<label class="col-sm-5 control-label text-sm-right ">Insurance Phone No</label>
										<div class="col-sm-7">
											<input-mask class="form-control" v-model="applicationDetails.insurancePhoneNo" mask="(999)-999-9999" placeholder="Please enter Insurance Phone No" maskChar="_"></input-mask>
											<div v-if="validationerrors.insurancePhoneNo != undefined" class="text-danger">
												{{ validationerrors.insurancePhoneNo }}
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group row">
										<label class="col-sm-5 control-label text-sm-right ">Member Number</label>
										<div class="col-sm-7">
											<input type="text" class="form-control" v-model="applicationDetails.insuranceMemberNo" placeholder="Enter member no"/>
											<div v-if="validationerrors.insuranceMemberNo != undefined" class="text-danger">
												{{ validationerrors.insuranceMemberNo }}
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group row">
									<label class="col-sm-5 control-label text-sm-right ">Group Number</label>
									<div class="col-sm-7">
										<input type="text" class="form-control" v-model="applicationDetails.insuranceGroupNo" placeholder="Enter group no"/>
										<div v-if="validationerrors.insuranceGroupNo != undefined" class="text-danger">
											{{ validationerrors.insuranceGroupNo }}
										</div>
									</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group row">
										<label class="col-sm-5 control-label text-sm-right ">Policy Holder</label>
										<div class="col-sm-7">
											<select class="form-control custom-select" v-model="applicationDetails.insurancePolicyHolder">
												<option value="" selected>--Select--</option>
												<option v-for="data in accountholdertypes" :key="data.key" :value="data.key">{{ data.label }}</option>
											</select>
											<div v-if="validationerrors.insurancePolicyHolder !=undefined" class="text-danger">
												{{ validationerrors.insurancePolicyHolder }}
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group row">
										<label class="col-sm-5 control-label text-sm-right">Insurance Proof1</label>
										<div class="col-sm-7" style="padding-top:10px;">
											<p v-if="applicationDetails.insuranceProof1 == null">Not available</p>
											<a v-if="applicationDetails.insuranceProof1 != null" :href="applicationDetails.insuranceProof1" target="_blank">Download</a>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group row">
										<label class="col-sm-5 control-label text-sm-right">Insurance Proof2</label>
										<div class="col-sm-7" style="padding-top:10px;">
											<p v-if="applicationDetails.insuranceProof2 == null">Not available</p>
											<a v-if="applicationDetails.insuranceProof2 != null" :href="applicationDetails.insuranceProof2" target="_blank">Download</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<div class="panel panel-light">
				<div class="panel-heading">
					<h3 class="panel-title">Medical information</h3>
				</div>
				<div class="panel-body">
					<section class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-md-4">
								<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right">Resident in a congregate care setting</label>
								<div class="col-sm-7">
									<select class="form-control custom-select" v-model="applicationDetails.isResidentInCongregateSetting">
										<option value="" selected>--Select--</option>
										<option v-for="data in commonoptions" :key="data.key" :value="data.key">{{ data.label }}</option>
									</select>
									<div v-if=" validationerrors.isResidentInCongregateSetting !=undefined" class="text-danger">
										{{ validationerrors.isResidentInCongregateSetting }}
									</div>
								</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right ">Employed in healthcare</label>
								<div class="col-sm-7">
									<select class="form-control custom-select" v-model="applicationDetails.employedInHealthCare">
										<option value="" selected>--Select--</option>
										<option v-for="data in commonoptions":key="data.key" :value="data.key">{{ data.label }}</option>
									</select>
									<div v-if="validationerrors.employedInHealthCare != undefined" class="text-danger">
										{{ validationerrors.employedInHealthCare }}
									</div>
								</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group row">
									<label class="col-sm-5 control-label text-sm-right">Hospitalized</label>
									<div class="col-sm-7">
										<select class="form-control custom-select" v-model="applicationDetails.isHospitalized">
											<option value="" selected>--Select--</option>
											<option v-for="data in commonoptions" :key="data.key" :value="data.key">{{ data.label }}</option>
										</select>
										<div v-if="validationerrors.isHospitalized != undefined" class="text-danger">
											{{ validationerrors.isHospitalized }}
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="col-md-4">
								<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right ">Insurance Card Preview</label>
								<div class="col-sm-7">
									<p v-if="applicationDetails.insuranceProof1 == null" class="">No file uploaded</p>
									<a v-if="applicationDetails.insuranceProof1 != null" class="" :href="applicationDetails.insuranceProof1" target="_blank">Download</a>
								</div>
								</div>
							</div> -->
						</div>
						<div class="row">
							<div class="col-md-4">
								<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right">In ICU</label>
								<div class="col-sm-7">
									<select class="form-control custom-select" v-model="applicationDetails.inICU">
										<option value="" selected>--Select--</option>
										<option v-for="data in commonoptions" :key="data.key" :value="data.key">{{ data.label }}</option>
									</select>
									<div v-if="validationerrors.inICU != undefined" class="text-danger">
										{{ validationerrors.inICU }}
									</div>
								</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right">Symptomatic as defined by CDC</label>
								<div class="col-sm-7">
									<select class="form-control custom-select" v-model="applicationDetails.isSymptomatic">
										<option value="" selected>--Select--</option>
										<option v-for="data in commonoptions" :key="data.key" :value="data.key">{{ data.label }}</option>
									</select>
									<div v-if="validationerrors.isSymptomatic != undefined" class="text-danger">
										{{ validationerrors.isSymptomatic }}
									</div>
								</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-group row">
									<label class="col-sm-5 control-label text-sm-right ">Symptom onset date</label>
									<div class="col-sm-7">
										<!-- <datepicker placeholder="Enter specimen collection date" :input-class="'form-control'" format="MM-dd-yyyy" v-model="onsetDate" >
										</datepicker> -->
										<datetime format="MM-DD-YYYY" placeholder="Enter Symptom onset date" v-model="onsetDate"></datetime>
										<div v-if="validationerrors.symptomOnsetDate != undefined" class="text-danger">
											{{ validationerrors.symptomOnsetDate }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-4" v-if="patientDetails.gender != 'Male'">
								<div class="form-group row">
									<label class="col-sm-5 control-label text-sm-right">Pregnant</label>
									<div class="col-sm-7">
										<select class="form-control custom-select" v-model="applicationDetails.pregnancyStatus">
											<option value="" selected>--Select--</option>
											<option v-for="data in commonoptions" :key="data.key" :value="data.key">{{ data.label }}</option>
										</select>
										<div v-if="validationerrors.pregnancyStatus != undefined" class="text-danger">
											{{ validationerrors.pregnancyStatus }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					</section>
				</div>
			</div>
			<div class="panel panel-light">
			<div class="panel-heading">
				<h3 class="panel-title">Test information</h3>
			</div>
			<div class="panel-body">
				<section class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right ">First test</label>
							<div class="col-sm-7">
								<select class="form-control custom-select" v-model="applicationDetails.isFirstTest">
									<option value="" selected>--Select--</option>
									<option v-for="data in commonoptions" :key="data.key" :value="data.key">{{ data.label }}</option>
								</select>
								<div v-if="validationerrors.isFirstTest != undefined" class="text-danger">
									{{ validationerrors.isFirstTest }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Specimen type</label>
							<div class="col-sm-7">
								<select class="form-control custom-select" v-model="applicationDetails.specimenType">
									<option value="" selected>--Select--</option>
									<option value="NP Swab">NP Swab</option>
									<option value="Nasal Swab">Nasal Swab</option>
									<option value="NP">NP</option>
								</select>
								<div v-if="validationerrors.specimenType != undefined" class="text-danger">
									{{ validationerrors.specimenType }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Test result</label>
							<div class="col-sm-7">
								<select class="form-control custom-select" v-model="applicationDetails.testResult">
									<option value="" selected>--Select--</option>
									<option v-for="data in testresulttypes" :key="data.key" :value="data.key">{{ data.label }}</option>
								</select>
								<div v-if="validationerrors.testResult != undefined" class="text-danger">
									{{ validationerrors.testResult }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Test order date</label>
							<div class="col-sm-7">
								<datetime format="MM-DD-YYYY H:i:s" v-model="applicationDetails.testOrderDate"></datetime>
								<div v-if="validationerrors.testOrderDate != undefined" class="text-danger">
									{{ validationerrors.testOrderDate }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Accession #/Specimen ID</label>
							<div class="col-sm-7">
								<input type="text" maxlength="24"  class="form-control" v-model="applicationDetails.specimenId" placeholder="Enter specimen id" />
								<div v-if="validationerrors.specimenId != undefined" class="text-danger">
									{{ validationerrors.specimenId }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right">Download Test Report</label>
								<div class="col-sm-7" style="padding-top:10px;">
									<p v-if="!isPublished" class="text-danger">Report Not Ready</p>
									<a v-if="isPublished" href="Javascript:void(0);" @click="dowloadReport()">Download</a>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Test date</label>
							<div class="col-sm-7">
								<datetime format="MM-DD-YYYY H:i:s" v-model="applicationDetails.testDate"></datetime>
								<div v-if="validationerrors.testDate != undefined" class="text-danger">
									{{ validationerrors.testDate }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Specimen collection date</label>
							<div class="col-sm-7">
								<datetime format="MM-DD-YYYY H:i:s" v-model="applicationDetails.specimenCollectionDate"></datetime>
								<div v-if="validationerrors.specimenCollectionDate !=undefined" class="text-danger">
									{{ validationerrors.specimenCollectionDate }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Loinc test ID</label>
							<div class="col-sm-7">
								<input type="text" maxLength="15" class="form-control" v-model="applicationDetails.loincTestId" placeholder="Enter loinc testid"/>
								<div v-if="validationerrors.loincTestId != undefined" class="text-danger">
									{{ validationerrors.loincTestId }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">CLIA No</label>
							<div class="col-sm-7">
								<input type="text" maxLength="15" class="form-control" v-model="applicationDetails.cliaNo" placeholder="Enter CLIA Number"/>
								<div v-if="validationerrors.cliaNo != undefined" class="text-danger">
									{{ validationerrors.cliaNo }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Specimen received date</label>
							<div class="col-sm-7">
								<datetime format="MM-DD-YYYY H:i:s" v-model="applicationDetails.specimenReceivedDate"></datetime>
								<div v-if="validationerrors.specimenReceivedDate != undefined" class="text-danger">
									{{ validationerrors.specimenReceivedDate }}
								</div>
							</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right m-t-10 switch">Publish</label>
							<div class="col-sm-7">
								<v-switch
								v-model="isPublished"
								></v-switch>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right ">Test description</label>
								<div class="col-sm-7">
									<textarea rows="4" maxLength="250" class="form-control" v-model="applicationDetails.testDescription" placeholder="Enter test description" style="resize: none;"></textarea>
									<div v-if="validationerrors.testDescription != undefined" class="text-danger">
										{{ validationerrors.testDescription }}
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="col-md-4">
							<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right">Test Result Proof 1</label>
								<div class="col-sm-7">
									<p v-if="applicationDetails.testResultProof1 == null" class="">No file uploaded</p>
									<a v-if="applicationDetails.testResultProof1 != null" class="" :href="applicationDetails.testResultProof1" target="_blank">Download</a
									>
								</div>
							</div>
						</div> -->
						<!-- <div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Upload Proof 1</label>
							<div class="col-sm-7">
								<input type="file" ref="pdfupload" v-on:change="handleTestProofUpload(applicationDetails.event_id, applicationDetails.slot_id, applicationDetails.id,'pdf')" class="form-control"	/>
							</div>
							</div>
						</div> -->
						<!-- <div class="col-md-4">
							<div class="form-group row">
								<label class="col-sm-5 control-label text-sm-right">Test Result Proof 2</label>
								<div class="col-sm-7">
									<p v-if="applicationDetails.testResultProof2 == null" class="">No file uploaded</p>
									<a v-if="applicationDetails.testResultProof2 != null" class="" :href="applicationDetails.testResultProof2" target="_blank">Download</a>
								</div>
							</div>
						</div> -->
						<!-- <div class="col-md-4">
							<div class="form-group row">
							<label class="col-sm-5 control-label text-sm-right">Upload Proof 2</label>
							<div class="col-sm-7">
								<input type="file" ref="imgupload" v-on:change="handleTestProofUpload(applicationDetails.event_id,applicationDetails.slot_id,applicationDetails.id,'img')" class="form-control"/>
							</div>
							</div>
						</div> -->
					</div>
				</div>
				</section>
				<div class="text-center mt-4">
					<button type="button" @click="saveApplication" class="btn btn-primary">Update</button>
				</div>
			</div>
			</div>
		</div>
		</div>
	</div>
</template>
<script>
import moment from "moment";
import PatientProfileForm from '../PatientProfile/PatientProfileForm';
export default {
	components: {
			PatientProfileForm
		},
	computed: {
        applicationid() {
            return this.$route.params.applicationid;
        },
    },
	data() {
		return {
			patientDetails: {},
			applicationDetails: {},
			genders: this.$store.getters.getGenders,
			patientRelations: this.$store.getters.getPatientRelations,
			races: this.$store.getters.getRaces,
			ethnicities: this.$store.getters.getEthnicities,
			commonoptions: this.$store.getters.getCommonOptions,
			testresulttypes: this.$store.getters.getTestResultTypes,
			accountholdertypes: this.$store.getters.getAccountHolderTypes,
			validationerrors: {},
			onsetDate: '',
			isPublished: false
		};
	},
	methods: {
		fixDate(event) {
            return moment(event).format("MM-DD-YYYY");
        },
		loadApplicationDetails() {
			axios
			.get(this.$store.getters.getAPIBasePath +"/applications/view/" + this.applicationid)
			.then((response) => {
				console.log('response:',response)
				let appData = response.data.recordinfo;
				let patientData = response.data.recordinfo.patientInfo;
				
				const { relationToPatient: patientRelationship, ...rest } = patientData;
                this.patientDetails = { patientRelationship, ...rest }
				const { id: applicationId, ...rests } = appData;
                this.applicationDetails = { applicationId, ...rests}

				this.isPublished = (appData.publishResult)? true:false;
				this.onsetDate = this.applicationDetails.symptomOnsetDate;
				this.patientDetails.patientRelationship = (patientData.relationToPatient) ? patientData.relationToPatient : '';
				this.patientDetails.gender = (patientData.gender==null)?'':patientData.gender;
				this.patientDetails.race = (patientData.race==null)?'':patientData.race;
				this.patientDetails.ethnicity = (patientData.ethnicity==null)?'':patientData.ethnicity;
				this.applicationDetails.isResidentInCongregateSetting = (appData.isResidentInCongregateSetting==null)?'':appData.isResidentInCongregateSetting;
				this.applicationDetails.employedInHealthCare = (appData.employedInHealthCare==null)?'':appData.employedInHealthCare;
				this.applicationDetails.isHospitalized = (appData.isHospitalized==null)?'':appData.isHospitalized;
				this.applicationDetails.inICU = (appData.inICU==null)?'':appData.inICU;
				this.applicationDetails.isSymptomatic = (appData.isSymptomatic==null)?'':appData.isSymptomatic;
				this.applicationDetails.isFirstTest = (appData.isFirstTest==null)?'':appData.isFirstTest;
				this.applicationDetails.testResult = (appData.testResult==null)?'':appData.testResult;
				this.applicationDetails.specimenType = (appData.specimenType==null)?'':appData.specimenType;
				this.applicationDetails.insurancePolicyHolder = (appData.insurancePolicyHolder==null)?'':appData.insurancePolicyHolder;
			})
			.catch((error) => {
				if(!error.response.data.headers.length){
					this.$awn.alert(error.response.data.headers.message);
				}
			});
		},
		saveApplication(){
			if(this.patientDetails.gender==='Male'){
				this.applicationDetails.pregnancyStatus="Unknown";
			}
			this.applicationDetails.symptomOnsetDate = this.onsetDate;
			this.applicationDetails.publishResult = (this.isPublished) ? 1 : 0;
			// let formData = new FormData();
			// let appInfo = this.applicationDetails;
			// delete appInfo.patientInfo;
			// jQuery.each(appInfo, function( key, value ) {
			// 	formData.append(key, value);
			// });
			axios.post(this.$store.getters.getAPIBasePath + '/applications/save', this.applicationDetails)
			.then(response => {
				this.$awn.success('Application details updated successfully');
				this.validationerrors = {}
			})
			.catch(error => {
				if(!error.response.data.headers.length){
					this.$awn.alert(error.response.data.headers.message);
				}
				if(!error.response.data.errorlist.length){
					this.validationerrors = error.response.data.errorlist;
				}
			});
		},
		dowloadReport(){
			axios.get(this.$store.getters.getAPIBasePath+'/applications/download/report/'+this.applicationid,{responseType: 'arraybuffer'})
			.then(response => {
				console.log(response.data)
				this.$awn.warning('Please allow popups when prompted to download Report');
				let blob = new Blob([response.data], { type: 'application/pdf' });
				let url = window.URL.createObjectURL(blob);
				window.open(url);
			})
			.catch(error => {
				console.log(error.response.data);
				this.$awn.alert('Error occured while downloading report.');
			});
		}
		
	},
	mounted() {
		this.loadApplicationDetails();
	},
};
</script>
