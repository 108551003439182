window.axios = require('axios')
window.$ = require('jquery')
window.jQuery = require('jquery')

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-awesome-notifications/dist/styles/style.css';
import './assets/css/icons.css';
import './assets/css/style.css';

import Vue from 'vue'
import Vuex from 'vuex';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';
import Router from 'vue-router';
import InputMask from 'vue-input-mask';
import Datepicker from 'vuejs-datepicker';
import datetime from 'vuejs-datetimepicker';
import VueAWN from "vue-awesome-notifications";
import BootstrapVue from 'bootstrap-vue';

import routes from './router'
import storeData from './store'
import filters from './filters';

import App from './App.vue'
import Master from "./components/Layout/Master.vue";
import Default from "./components/Layout/Default.vue";

Vue.config.productionTip = false;

let vueAWNOptions = {
	position: 'top-right',
	maxNotifications: 3,
	animationDuration: 500,
	icons: {
		enabled: true,
		success: "check",
	}
};

Vue.use(Router);
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(VueAWN, vueAWNOptions);
Vue.use(BootstrapVue);

Vue.component('input-mask', InputMask);
Vue.component('dashboard-layout', Master);
Vue.component('default-layout', Default);
Vue.component('datepicker', Datepicker);
Vue.component('datetime', datetime);

for (let name in filters) {
	Vue.filter(name, filters[name]);
}


let router = new Router({
	routes,
	mode: 'history'
});

const store = new Vuex.Store(storeData);

router.beforeEach((to, from, next) => {
	const publicPages = ['/login', '/', '/forgotpassword'];
	const authRequired = !publicPages.includes(to.path);
	const isLoggedIn = localStorage.getItem(store.getters.getLocalStorageName);
	if (to.path.startsWith("/resetpassword")) {
		return next();
	}
	if (authRequired && !isLoggedIn) {
		console.log('redirecting');
		return next('/login');
	}
	if (isLoggedIn && !authRequired) {
		console.log('secured');
		return next('/dashboard');
	}
	next();
});

window.axios.interceptors.request.use(config => {
	return config;
});

window.axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response.status == 401) {
		console.log('expired');
		store.commit("saveAccessToken", null);
		localStorage.removeItem(store.getters.getLocalStorageName);
		localStorage.removeItem(store.getters.getLocalStorageKeyName);
		router.push('login');
	}
	return Promise.reject(error) // this is the important part
});

new Vue({
	router,
	vuetify,
	store,
	render: h => h(App),
}).$mount('#app');