import ViewTestkits from '../components/TestKit/ViewTestkits';
import TestkitEdit from '../components/TestKit/TestkitEdit';

export default [
    {
        path: '/testkits',
        name: 'devicedata',
        component: ViewTestkits,
        meta: { layout: 'dashboard' }
    },
    {
        path: '/testkits/edit/:kitid',
        name: 'testkitedit',
        component: TestkitEdit,
        meta: { layout: 'dashboard' }
    },
];