<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-light">
                    <div class="panel-heading">
                        <h3 class="panel-title col-md-6">Customer Accounts</h3>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12 m-t-5">
                                <div class="row">
                                    <DataTableSearch @search-update="updateSearchParams"></DataTableSearch>
                                </div>
                                <table class="table table-bordered table-striped mb-0" id="datatable-default">
                                    <thead>
                                        <tr>
                                            <th>Customer Id</th>
                                            <th>First Name</th>
                                            <th>Middle Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>DOB</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="customers.length==0">
                                            <td colspan="7" align="center">No Customer Accounts Found</td>
                                        </tr>
                                        <tr v-for="(info, index) in customers" :key="index">
                                            <th scope="row">{{info.id}}</th>
                                            <td>{{ info.firstName }}</td>
                                            <td>{{ info.middleName }}</td>
                                            <td>{{ info.lastName }}</td>
                                            <td>{{ info.email }}</td>
                                            <td>{{info.dob }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="col-md-12">
                                    <b-pagination v-if="customers.length!=0" size="md" class='pull-right'
                                        :current-page="searchQueries.currentpage"
                                        :total-rows="searchQueries.totalrecords" v-model="searchQueries.pageNo"
                                        :per-page="searchQueries.limit" @input="loadRecords">
                                    </b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //import iziToast from 'izitoast';
    import DataTableSearch from '../CommonElements/DataTableSearch';
    export default {
        components: {
            DataTableSearch
        },
        data() {
            return {
                customers: [],
                searchQueries: {
                    limit: 20,
                    pageNo: 1,
                    searchString:'',
                    totalrecords:'',
                    totalpages:''
                },
            }
        },
        methods: {
            updateSearchParams(newparams) {
                this.searchQueries.currentpage = 1;
                this.searchQueries.searchString = newparams.search;
                this.loadRecords();
            },
            loadRecords() {
                axios.post(this.$store.getters.getAPIBasePath + "/customers",this.searchQueries)
                    .then(response => {
                        let result = response.data;
                        this.customers = result.data;
                        this.searchQueries.pageNo = result.currentpage;
                        this.searchQueries.totalrecords = result.totalrecords;
                        this.searchQueries.totalpages = result.totalpages;
                    })
                    .catch(error => {
                        console.log(error.response.data);
                        // iziToast.error({
                        //     title: 'Warning!',
                        //     message: error.response.data.message,
                        //     position: 'topRight'
                        // });
                        this.customers = [];
                    });
            }
        },
        mounted() {
            this.loadRecords();
        }
    }
</script>