<template>
    <div class="form-inline col-md-12 margin-search">
        <!-- <div class="pull-left">
            <select class="form-control" @change="changeLimit()" v-model="searchdata.limit">
                <option v-for="(value, index) in definedlimits" :key="index" :value="value">{{value}}</option>
            </select>
        </div> -->
        <div class="input-group float-right">
            <input type="text" @keyup.enter="emitBackInfo" class="form-control" placeholder="Search for..."
                v-model="searchdata.search">
            <div class="input-group-addon p-0">
                <button type="button" @click="emitBackInfo" class="btn btn-light"><i class="fa fa-search"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchdata: {
                    search: ''
                }
            }
        },
        methods: {
            emitBackInfo() {
                this.$emit("search-update", this.searchdata);
            },
            changeLimit() {
                // this.$store.commit('changeDefaultLimit', this.searchdata.limit);
                this.emitBackInfo();
            }
        }
    }
</script>