import ViewPatientProfiles from '../components/PatientProfile/ViewPatientProfiles';
import ProfileDetails from '../components/PatientProfile/ProfileDetails';


export default [
    {
        path: '/patients',
        name: 'patientsprofile',
        component: ViewPatientProfiles,
        meta: { layout: 'dashboard' }
    },
    {
        path: '/patients/profile/details/:patientId',
        name: 'profiledetails',
        component: ProfileDetails,
        meta: { layout: 'dashboard' }
    }
];