<template>
	<div v-if="userConfig == 1">
		<v-app id="inspire">
			<v-navigation-drawer v-model="drawer" enable-resize-watcher app dark class="dark">
				<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="title">
						<div class="text-center">
							<a href="" aria-current="page" class="logo router-link-exact-active router-link-active">
							<img src="../../../src/assets/images/logo.png" height="28" class="logo-img-backend"/>
							</a>
						</div>
					</v-list-item-title>
					<v-list-item-subtitle>
						<div class="user-details">
							<div class="text-center">
								<img src="../../../src/assets/images/portrait-lindamelvin.jpg" alt="" class="rounded-circle"/>
							</div>
							<div class="user-info">
								<p><b>{{ userData.firstName }} {{ userData.lastName }}</b></p>
								<p class="text-muted m-0">
									<i class="fa fa-dot-circle-o text-success"></i>Admin
								</p>
							</div>
						</div>
					</v-list-item-subtitle>
				</v-list-item-content>
				</v-list-item>
				<v-divider></v-divider>
				<!-- <v-list dense nav>
							<v-list-item v-for="item in menu_items" :key="item.title" link>
								<v-list-item-icon>
									<v-icon>{{ item.icon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ item.title }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list> -->
				<!-- <v-list-group prepend-icon="mdi-alarm" value="true">
							<v-list-item slot="activator">
								<v-list-item-title>Events</v-list-item-title>
							</v-list-item>
							<v-list-item v-for="item in sub_menu_items" :key="item.title" link>
								<v-list-item-icon>
									<v-icon>{{ item.icon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>{{ item.title }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-group> -->
				<v-list dense nav>
					<div v-for="item in leftBarMenuItems" :key="item.to">
						<v-list-item v-if="item.access.includes(userData.role)" :to="item.to" link>
							<v-list-item-icon><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
							<v-list-item-content><v-list-item-title>{{ item.title }}</v-list-item-title></v-list-item-content>
						</v-list-item>
					</div>
				</v-list>
			</v-navigation-drawer>
			<v-app-bar app>
				<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
				<v-toolbar-title>&nbsp;</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-menu v-for="([text, rounded], index) in btns" :key="text" :rounded="rounded" offset-y>
					<template v-slot:activator="{ attrs, on }">
						<v-btn :color="colors[index]" v-bind="attrs" v-on="on">
						<v-icon>mdi-account-circle</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item v-for="item in profile_items" :key="item.title" :to="item.path" link>
						<v-list-item-icon>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-app-bar>
			<v-main>
				<slot />
			</v-main>
		</v-app>
	</div>
</template>
<script>
import { decryptData } from "../../helpers/AuthUtils";

export default {
data() {
	return {
		userConfig: 0,
		userData: this.$store.getters.getUserData,
		leftBarMenuItems: [
			{
				title: "Dashboard",
				icon: "mdi-view-dashboard",
				to: "/dashboard",
				access:['admin','staff','doctor']
			},
			{
				title: "Applications",
				icon: "mdi-receipt",
				to: "/applications",
				access:['admin','staff','doctor']
			},
			{
				title: "Device Data",
				icon: "mdi-upload",
				to: "/testkits",
				access:['admin','staff']
			},
			{
				title: "Patient Profiles",
				icon: "mdi-hospital",
				to: "/patients",
				access:['admin','staff','doctor']
			},
			{
				title: "Customers",
				icon: "mdi-account",
				to: "/customers",
				access:['admin','staff']
			},
			{
				title: "Admin Accounts",
				icon: "mdi-group",
				to: "/users",
				access:['admin']
			},
		],
		profile_items: [
			{
				title: "Change Password",
				icon: "mdi-key-variant",
				path: "/changepassword",
			},
			{ 
				title: "Logout", 
				icon: "mdi-lock", 
				path: "/logout" 
			},
		],
		btns: [["Removed", "0"]],
		colors: [""],
		right: null,
		drawer: null,
	};
},
mounted() {
	let accessToken = this.$store.getters.getAccessToken;
	if (accessToken == null) {
		let savedToken = localStorage.getItem(
			this.$store.getters.getLocalStorageName
		);
		if (!savedToken) {
			this.$router.push("login");
		}
		accessToken = decryptData(savedToken);
	}
	window.axios.defaults.headers.common["Content-Type"] = "application/json";
	window.axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
	this.loadUserData();
},
methods: {
	loadUserData() {
		axios.get(this.$store.getters.getAPIBasePath + "/profile")
		.then((response) => {
			this.$store.commit("saveUserData", response.data.recordinfo);
			this.userData = response.data.recordinfo;
			this.userConfig = 1;
			this.loadMasterData();
		})
		.catch((error) => {
			console.log(error.response.data);
		});
	},
	loadMasterData() {
		this.loadAccountHolderTypes();
		this.loadCommonOptions();
		this.loadTestTypes();
		this.loadTestResultTypes();
		this.loadPregnancyStates();
		this.loadEthnicities();
		this.loadRaces();
		this.loadPatientRelations();
		this.loadGenders();
		this.loadAdminRoles();
	},
	loadAccountHolderTypes() {
		axios.get(this.$store.getters.getAPIBasePath + "/lookup/account_holder_type")
		.then((response) => {
			this.$store.commit("saveAccountHolderTypes", response.data.data);
		})
		.catch((error) => {
			console.log(error.response);
		});
	},
	loadCommonOptions() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/common_options")
		.then((response) => {
		this.$store.commit("saveCommonOptions", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
	loadAdminRoles() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/adminroles")
		.then((response) => {
		this.$store.commit("saveAdminRoles", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
	loadTestTypes() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/test_type")
		.then((response) => {
		this.$store.commit("saveTestTypes", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
	loadTestResultTypes() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/test_result_types")
		.then((response) => {
		this.$store.commit("saveTestResultTypes", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
	loadPregnancyStates() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/pregnancy_status")
		.then((response) => {
		this.$store.commit("savePregnancyStates", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
	loadEthnicities() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/ethnicity")
		.then((response) => {
		this.$store.commit("saveEthnicities", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
	loadRaces() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/race")
		.then((response) => {
		this.$store.commit("saveRaces", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
	loadPatientRelations() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/patient_relation")
		.then((response) => {
		this.$store.commit("savePatientRelations", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
	loadGenders() {
	axios
		.get(this.$store.getters.getAPIBasePath + "/lookup/gender")
		.then((response) => {
		this.$store.commit("saveGenders", response.data.data);
		})
		.catch((error) => {
		console.log(error.response);
		});
	},
},
};
</script>
