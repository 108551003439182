<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-light">
                    <div class="panel-heading">
                        <h3 class="panel-title col-md-6">Patient Profile</h3>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12 m-t-5">
                                <div class="row">
                                    <DataTableSearch @search-update="updateSearchParams"></DataTableSearch>
                                </div>
                                <table class="table table-bordered table-striped mb-0" id="datatable-default">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                            <th>Gender</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="patientProfiles.length==0">
                                            <td colspan="7" align="center">No Patient Profiles Found</td>
                                        </tr>
                                        <tr v-for="(patientProfile, index) in patientProfiles" :key="index">
                                            <td>{{patientProfile.id}}</td>
                                            <td>{{patientProfile.firstName}}</td>
                                            <td>{{patientProfile.lastName}}</td>
                                            <td>{{patientProfile.email}}</td>
                                            <td>{{patientProfile.phoneNo}}</td>
                                            <td>{{patientProfile.gender}}</td>
                                            <td>
                                                <router-link
                                                    :to="{ name: 'profiledetails', params: { patientId: patientProfile.id }}">
                                                    <span class="ti-pencil"></span> View
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="col-md-12">
                                    <b-pagination v-if="patientProfiles.length!=0" size="md" class='pull-right'
                                        :current-page="searchQueries.currentpage"
                                        :total-rows="searchQueries.totalrecords" v-model="searchQueries.pageNo"
                                        :per-page="searchQueries.limit" @input="loadRecords">
                                    </b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import iziToast from 'izitoast';
    import DataTableSearch from '../CommonElements/DataTableSearch';
    export default {
        components: {
            DataTableSearch
        },
        data() {
            return {
                patientProfiles: [],
                searchQueries: {
                    limit: 10,
                    pageNo: 1,
                    searchString:'',
                    totalrecords:'',
                    totalpages:''
                },
            }
        },
        methods: {
            updateSearchParams(newparams) {
                this.searchQueries.pageNo = 1;
                this.searchQueries.searchString = newparams.search;
                this.loadRecords();
            },
            loadRecords() {
            axios
                .post(
                this.$store.getters.getAPIBasePath + "/patientprofiles",this.searchQueries )
                .then((response) => {
                    let result = response.data;
                    this.patientProfiles = result.data;
                    this.searchQueries.pageNo = result.currentpage;
                    this.searchQueries.totalrecords = result.totalrecords;
                    this.searchQueries.totalpages = result.totalpages;
                })
                .catch((error) => {
                    console.log(error.response.data);
                    // iziToast.error({
                    //     title: 'Warning!',
                    //     message: error.response.data.message,
                    //     position: 'topRight'
                    // });
                this.patientProfiles = [];
                });
            }
        },
        mounted() {
            this.loadRecords();
        },
    }
</script>