<template>
    <div class="panel panel-light">
        <div class="panel-heading">
            <h3 class="panel-title">Profile Information</h3>
        </div>
        <div class="panel-body">
            <section class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">First Name</label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="patientDetails.firstName" placeholder="Enter first name" />
                                    <div v-if="validationerrors.firstName != undefined" class="text-danger">
                                        {{
                                            validationerrors.firstName
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">Middle Name</label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="patientDetails.middleName" placeholder="Enter Middle Name" />
                                    <div v-if="validationerrors.middleName != undefined" class="text-danger">
                                        {{
                                            validationerrors.middleName
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">Last Name</label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="patientDetails.lastName" placeholder="Enter Last Name" />
                                    <div v-if="validationerrors.lastName != undefined" class="text-danger">
                                        {{
                                            validationerrors.lastName
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">Email</label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="patientDetails.email" placeholder="Enter Email" />
                                    <div v-if="validationerrors.email != undefined" class="text-danger">
                                        {{
                                            validationerrors.email
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">Phone Number</label>
                                <div class="col-sm-7">
                                    <!-- <input type="text" class="form-control" v-model="patientDetails.phoneNo" placeholder="Enter Phone Number" /> -->
                                    <input-mask class="form-control" v-model="patientDetails.phoneNo" mask="(999)-999-9999" placeholder="Please enter Phone No" maskChar="_"></input-mask>
                                    <div v-if="validationerrors.phoneNo != undefined" class="text-danger">
                                        {{
                                            validationerrors.phoneNo
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right ">Gender</label>
                                <div class="col-sm-7">
                                    <select class="form-control custom-select" v-model="patientDetails.gender">
                                        <option value="" selected>--Select--</option>
                                        <option v-for="data in genders"	:key="data.key"	:value="data.key">{{ data.label }}</option>
                                    </select>
                                    <div v-if="validationerrors.gender != undefined" class="text-danger" >
                                        {{
                                            validationerrors
                                                .gender
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">DOB</label>
                                <div class="col-sm-7">
                                    <!-- <datepicker placeholder="Enter DOB" v-model="dateofbirth" :input-class="'form-control'" format="MM-dd-yyyy"></datepicker> -->
                                    <datetime format="MM-DD-YYYY" placeholder="Enter DOB" v-model="dateofbirth"></datetime>
                                    <div v-if="validationerrors.dob != undefined" class="text-danger">
                                        {{
                                            validationerrors.dob
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right ">Race</label>
                                <div class="col-sm-7">
                                    <select class="form-control custom-select" v-model="patientDetails.race">
                                        <option value="" selected>--Select--</option>
                                        <option v-for="data in races" :key="data.key" :value="data.key">{{ data.label }}</option>
                                    </select>
                                    <div v-if="validationerrors.race != undefined" class="text-danger" >
                                        {{
                                            validationerrors
                                                .race
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right ">Ethnicity</label>
                                <div class="col-sm-7">
                                    <select class="form-control custom-select" v-model="patientDetails.ethnicity">
                                        <option value="">--Select--</option>
                                        <option v-for="data in ethnicities" :key="data.key" :value="data.key" >{{ data.label }}</option>
                                    </select>
                                    <div v-if="validationerrors.ethnicity != undefined" class="text-danger" >
                                        {{
                                            validationerrors
                                                .ethnicity
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right ">Relation</label>
                                <div class="col-sm-7">
                                    <select class="form-control custom-select" v-model="patientDetails.patientRelationship">
                                        <option value="" selected>--Select--</option>
                                        <option v-for="data in patientRelations" :key="data.key" :value="data.key" >{{ data.label }}</option>
                                    </select>
                                    <div v-if="validationerrors.patientRelationship != undefined" class="text-danger" >
                                        {{
                                            validationerrors
                                                .patientRelationship
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">Address</label>
                                <div class="col-sm-7">
                                    <input type="textarea" class="form-control" v-model="patientDetails.address" placeholder="Enter address" />
                                    <div v-if="validationerrors.address != undefined" class="text-danger">
                                        {{
                                            validationerrors.address
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">City</label>
                                <div class="col-sm-7">
                                    <input type="textarea" class="form-control" v-model="patientDetails.city" placeholder="Enter city" />
                                    <div v-if="validationerrors.city != undefined" class="text-danger">
                                        {{
                                            validationerrors.city
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">State</label>
                                <div class="col-sm-7">
                                    <input type="textarea" class="form-control" v-model="patientDetails.state" placeholder="Enter state" />
                                    <div v-if="validationerrors.state != undefined" class="text-danger">
                                        {{
                                            validationerrors.state
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group row align-items-center">
                                <label class="col-sm-5 control-label text-sm-right">Zipcode</label>
                                <div class="col-sm-7">
                                    <input type="textarea" class="form-control" v-model="patientDetails.zipCode" placeholder="Enter zipcode" />
                                    <div v-if="validationerrors.zipCode != undefined" class="text-danger">
                                        {{
                                            validationerrors.zipCode
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <button type="button" @click="updateProfile" class="btn btn-primary">Update</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    props: {
        patientDetails: Object
        },
    computed: {
        patientid() {
            return this.$route.params.patientId;
        },
    },
    data() {
        return {
            validationerrors: {},
            genders: this.$store.getters.getGenders,
            patientRelations: this.$store.getters.getPatientRelations,
            races: this.$store.getters.getRaces,
            ethnicities: this.$store.getters.getEthnicities,
            dateofbirth: ''
        }
    },
    watch: {
        patientDetails: function(val,oldVal){
            if(val.dob!=undefined){
                this.dateofbirth = val.dob;
            }
        }
    },
    methods: { 
        fixDate(event) {
            return moment(event).format("MM-DD-YYYY");
        },
        updateProfile() {
        this.validationerrors = {};
        this.patientDetails.dob = this.dateofbirth;
        axios.post(this.$store.getters.getAPIBasePath + '/patientprofiles/update/' + this.patientDetails.id, this.patientDetails)
            .then(response => {
                this.$awn.success('Patient profile updated successfully');
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                if(!error.response.data.errorlist.length){
                    this.validationerrors = error.response.data.errorlist;
                    console.log(this.validationerrors);
                }
            });
        }, 
    }
}
</script>
