import ViewUsers from '../components/AdminUsers/ViewUsers';
import ChangePassword from '../components/Auth/ChangePassword';

export default [
    {
        path: '/changepassword',
        name: 'changeuserpassword',
        component: ChangePassword,
        meta: { layout: 'dashboard' }
    },
    {
        path: '/users',
        name: 'viewusers',
        component: ViewUsers,
        meta: { layout: 'dashboard' }
    }
];