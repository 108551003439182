export default {
	state: {
		userdata: {},
		apibasepath: process.env.VUE_APP_BASE_URL,
		accesstoken: null,
		localStorageName: process.env.VUE_APP_LOCAL_STORAGE_NAME,
		localStorageKeyName: 'adminKey',
		accountHolderTypes: [],
		adminRoles: [],
		commonOptions: [],
		testResultTypes: [],
		testTypes: [],
		pregnancyStates: [],
		ethnicities: [],
		races: [],
		patientRelations: [],
		genders: [],
		testApplicationID: null,
		testApplicationPatientID: null,
		paginationconfig: {
			currentpage: 1,
			totalpages: 2,
			fromrecords: 1,
			torecords: 1,
			totalrecords: 1,
			startpage: 1,
			endpage: 1,
			maxpagepins: 6,
		}
	},
	getters: {
		getUserData(state) {
			return state.userdata;
		},
		getAPIBasePath(state) {
			return state.apibasepath;
		},
		getLocalStorageName(state) {
			return state.localStorageName;
		},
		getLocalStorageKeyName(state) {
			return state.localStorageKeyName;
		},
		getAccessToken(state) {
			return state.accesstoken;
		},
		getAccountHolderTypes(state) {
			return state.accountHolderTypes;
		},
		getCommonOptions(state) {
			return state.commonOptions;
		},
		getAdminRoles(state) {
			return state.adminRoles;
		},
		getTestTypes(state) {
			return state.testTypes;
		},
		getTestResultTypes(state) {
			return state.testResultTypes;
		},
		getPregnancyStates(state) {
			return state.pregnancyStates;
		},
		getEthnicities(state) {
			return state.ethnicities;
		},
		getRaces(state) {
			return state.races;
		},
		getPatientRelations(state) {
			return state.patientRelations;
		},
		getGenders(state) {
			return state.genders;
		},
		getRecentTestApplicationID(state) {
			return state.testApplicationID;
		},
		getRecentTestApplicationPatientID(state) {
			return state.testApplicationPatientID;
		},
		isTestApplicationLoaded(state) {
			return (state.testApplicationID != null && state.testApplicationPatientID != null) ? true : false;
		},
		getPaginationConfig(state) {
			return state.paginationconfig;
		}
	},
	mutations: {
		saveUserData(state, payload) {
			state.userdata = payload;
		},
		saveAccessToken(state, payload) {
			state.accesstoken = payload;
		},
		saveAccountHolderTypes(state, payload) {
			state.accountHolderTypes = payload;
		},
		saveCommonOptions(state, payload) {
			state.commonOptions = payload;
		},
		saveAdminRoles(state, payload) {
			state.adminRoles = payload;
		},
		saveTestTypes(state, payload) {
			state.testTypes = payload;
		},
		saveTestResultTypes(state, payload) {
			state.testResultTypes = payload;
		},
		savePregnancyStates(state, payload) {
			state.pregnancyStates = payload;
		},
		saveEthnicities(state, payload) {
			state.ethnicities = payload;
		},
		saveRaces(state, payload) {
			state.races = payload;
		},
		savePatientRelations(state, payload) {
			state.patientRelations = payload;
		},
		saveGenders(state, payload) {
			state.genders = payload;
		},
		saveRecentTestApplicationID(state, payload) {
			state.testApplicationID = payload;
		},
		saveRecentTestApplicationPatientID(state, payload) {
			state.testApplicationPatientID = payload;
		}
	},
	actions: {
	}
}