<template>
    <div>
        <div class="wrapper-page">
            <div class="panel panel-color panel-primary panel-pages">
                <div class="panel-body">
                    <h3 class="text-center m-t-0 m-b-30">
                        <span class=""><img src="../../assets/images/logo.png" alt="logo" height="80"></span>
                    </h3>
                    <form class="form-horizontal m-t-20" id="login-form" method="post"
                        autocomplete="off" role="form">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input  type="password" v-model="reset.newPassword" id="password" class="form-control form__input" placeholder="Password *"/>
                                <div v-if="resetErrors.newPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ resetErrors.newPassword }}</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.newPassword.$error && !$v.reset.newPassword.required">Password is required.</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.newPassword.$error && !$v.reset.newPassword.minLength">Password must have at least {{ $v.reset.newPassword.$params.minLength.min }} letters.</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.newPassword.$error && !$v.reset.newPassword.maxLength">Password cannot exceed {{ $v.reset.newPassword.$params.maxLength.max }} letters.</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input type="password" v-model="reset.confirmPassword" id="confirm_password" class="form-control form__input" placeholder="Confirm Password *"/>
                                <div v-if="resetErrors.confirmPassword != undefined" class="mt-1 mb-2 vuelidate-error">{{ resetErrors.confirmPassword }}</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.confirmPassword.$error && !$v.reset.confirmPassword.required">Password is required.</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.confirmPassword.$error && !$v.reset.confirmPassword.minLength">Password must have at least {{ $v.reset.confirmPassword.$params.minLength.min }} letters.</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.confirmPassword.$error && !$v.reset.confirmPassword.maxLength">Password cannot exceed {{ $v.reset.confirmPassword.$params.maxLength.max }} letters.</div>
                                <div class="mt-1 mb-2 vuelidate-error" v-if="$v.reset.confirmPassword.$error && !$v.reset.confirmPassword.sameAs">Both Passwords must be identical.</div>
                            </div>
                        </div>
                        <div class="form-group text-center m-t-20">
                            <div class="col-xs-12">
                                <button type="button" @click="performResetRequest()" class="btn btn-primary w-md waves-effect waves-light">Reset Password</button>
                            </div>
                        </div>
                        <div class="form-group m-t-30 m-b-0">
                            <div class="col-sm-7">
                                <router-link class="ForgetPwd" :to="{ name: 'login' }">Click here to Login</router-link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
export default {
    computed: {
        resetkey() {
            return this.$route.params.resetkey;
        }
    },
    data() {
        return {
            isKeyVerified:0,
            reset: {
                resetKey:'',
                newPassword: '',
                confirmPassword:''
            },
            resetErrors: {}
        }
    },
    validations: { 
        reset: {
            newPassword: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(20)
            },
            confirmPassword: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(20),
                sameAsPassword: sameAs('newPassword')
            }
        }
    },
    mounted(){
        this.verifyKey();
    },
    methods: {
        verifyKey(){
            axios.get(this.$store.getters.getAPIBasePath + '/auth/verify/passwordreset/'+this.resetkey)
            .then(response => {
                let apiResponse = response.data;
                this.isKeyVerified = 1;
                this.reset.resetKey = this.resetkey;
            })
            .catch(error => {
                if(!error.response.data.headers.length){
                    this.$awn.alert(error.response.data.headers.message);
                }
                this.$router.push('/login');
            });
        },
        performResetRequest() {
            this.resetErrors = {};
            this.$v.reset.$touch();
            if (!this.$v.reset.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/auth/passwordreset', this.reset)
                .then(response => {
                    this.$awn.success(response.data.headers.message);
                    this.$router.push('/login');
                })
                .catch(error => {
                    if(!error.response.data.headers.length){
                        this.$awn.alert(error.response.data.headers.message);
                    }
                    if(!error.response.data.errorlist.length){
                        this.resetErrors = error.response.data.errorlist;
                    }
                });
            }
            else{
                console.log('show error');
            }
        }
    }
}

</script>